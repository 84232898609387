import { graphql, navigate, useStaticQuery } from 'gatsby'
import __filter__ from 'lodash/filter'
import React, { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react'

import SupportIcon from '@cuidardigital/commons/assets/icons/support'
import { Search } from '@cuidardigital/commons/components/Inputs'
import { Loader } from '@cuidardigital/commons/components/Loader'
import { get, getSupport, sendUrl } from '@cuidardigital/commons/services/appointment'
import { logout } from '@cuidardigital/commons/services/auth'
import { getDoctorInfo } from '@cuidardigital/commons/services/doctor.services'
import IAppointment from '@cuidardigital/commons/types/IAppointment'
import IConsultationClosed from '@cuidardigital/commons/types/IConsultationClosed'
import IModalMessage from '@cuidardigital/commons/types/IModalMessage'
import {
	dateToISOWithTime,
	formatDate,
	isToday,
	orderDate
} from '@cuidardigital/commons/utils/date'
import EmptyState from '@cuidardigital/commons/assets/icons/emptyState'
import { validateEligibilityId } from '@cuidardigital/commons/services/elegibility'
import { PATIENT_DETAIL } from '../../routes'
import { store, TYPES } from '../../stores/app'
import TemplatePage from '../../templates/Page'
import { initUserSession } from '../../utils/session'
import CancelAppointment from '../cancelAppointment'
import { Appointment, ConsultationClosed, Support as SupportCard } from '../Cards'
import MessageModal from '../Modal'
import ConfirmResend from '../Modal/confirmResend'
import NewAppointment from '../newAppointment'

import Support from '../Support'

import {
	Col,
	ComponentWrapper,
	ContentWrapper,
	Grid,
	Icon,
	IconCalendar,
	NoContentGrid,
	OldAppointmentTitle,
	SidebarContainer,
	SupportButton,
	SupportText,
	ColButton,
	Button,
	Span,
	ButtonLink
} from './styles'

import iconCalendar from '../../assets/icons/svg/icon-calendario.svg'
import MobileMenuHome from '../Menu/Mobile/Home'
import DigitalCertificateModal from '../Modal/DigitalCertificate'

interface Dashboard {
	height: string | number
	title: string
	paragraphy: string
	buttonText: string
	image: { src: string }
	goToForm: () => void
}

interface INewAppointment {
	setShowNewAppointment: any
	setLoading: any
	setMessageToShow: any
	setShowMessage: any
}

interface ICancelAppointment {
	setShowCancelAppointment: any
	appointmentToCancel: string
	setLoading: any
	setMessageToShow: any
	setShowMessage: any
	state: any
}

interface IConsultation {
	cpf: string
	name: string
	email: string
}

interface ObjectLiteral {
	[key: string]: any
}

const isoStringToDateString = (str: string) => {
	const parsedDate = new Date(str)
	const pad = '00'
	const dayStr = parsedDate.getDate().toString()
	const day = pad.substring(0, pad.length - dayStr.length) + dayStr.toString()
	const monthStr = (parsedDate.getMonth() + 1).toString()
	const month = pad.substring(0, pad.length - monthStr.length) + monthStr.toString()
	const year = parsedDate.getFullYear()
	return `${day}/${month}/${year}`
}

const isoStringToHourString = (str: string) => {
	const parsedDate = new Date(str)
	return parsedDate.toTimeString().substring(0, 5)
}

const mapAppointmentClosedResult = (newList: any) =>
	orderDate(newList, 'desc').map((item: any) => {
		return {
			cpf: item.patient.cpf,
			name: item.patient.name,
			email: item.patient.email,
			hour: isoStringToHourString(item.date),
			date: isoStringToDateString(item.date),
			birthDay: item.patient.birthDay,
			phone: item.patient.phone,
			doctorEmail: item.doctor.email,
			link: item.doctorLink || '',
			consultationId: item.id
		}
	})

const mapAppointmentResult = (newList: any) =>
	orderDate(newList, 'asc').map((item: any) => {
		return {
			link: item.doctorLink || '',
			consultationId: item.id,
			name: item.patient.name,
			email: item.patient.email,
			hour: isoStringToHourString(item.date),
			date: isoStringToDateString(item.date),
			birthDay: item.patient.birthDay,
			phone: item.patient.phone,
			status: item.status,
			eligibilityId: item.eligibilityId,
			cpf: item.patient.cpf,
			doctorEmail: item.doctor.email,
			patientLink: item.patientLink,
			medicalEncounterId: item.medicalEncounterId,
			patientPhone: item.patientPhone
		}
	})

const limit = 20
let scrollInfinit = false
let scrollInfinitClosed = false
let isFetchingOpened = false
let isFetchingClosed = false

const statusEnum = {
	opened: ['CREATED', 'CONFIRMED'],
	closed: ['CANCELLED', 'FINISHED', 'OLD_CONFIRMED']
}

const Dashboard = ({ showDigitalCertificateModal, modalTour }) => {
	// @ts-ignore
	const { state, dispatch } = useContext(store)
	// @ts-ignore
	initUserSession(state, dispatch)
	const [fetchOnReturn, setFetchOnReturn] = useState(false)
	const [showNewAppointment, setShowNewAppointment] = useState(false)
	const [showCancelAppointment, setShowCancelAppointment] = useState({
		open: false,
		type: ''
	})
	const [appointmentToCancel, setAppointmentToCancel] = useState('')
	const [appointments, setAppointments] = useState([])
	const [consultationClosed, setConsultationClosed] = useState([])
	const [loading, setLoading] = useState(false)
	const [searchText, setSearchText] = useState('')
	const [filterText, setFilterText] = useState('')
	const [showMessage, setShowMessage] = useState(false)
	const [messageToShow, setMessageToShow] = useState<IModalMessage>()
	const [newList, setNewList] = useState([])
	const [page, setPage] = useState(0)
	const [pageTwo, setPageTwo] = useState(0)
	const [showConfirmResend, setShowConfirmResend] = useState(false)
	const [idResendLink, setIdResendLink] = useState('')
	const [isDigitalCertificate, setIsDigitalCertificate] = useState(false)
	const [digitalCertificateAcceptance, setDigitalCertificateAcceptance] = useState(null)
	const [typeResend, setTypeResend] = useState('')
	const [showSupport, setShowSupport] = useState(false)
	const [supportList, setSupportList] = useState([])
	const [eligibilityFailureMessage, setEligibilityFailureMessage] = useState('')
	const [mobileActive, setMobileActive] = useState('')

	const [totalPage, setTotalPage] = useState(0)
	const [totalPageTwo, setTotalPageTwo] = useState(0)

	const ref = useRef(null)
	const refOpened = useRef(null)

	const data = useStaticQuery(query)
	const {
		consultationsCarriedOut,
		paragraph,
		buttonNewAppointment
	} = data.saudeDigital.metadata.dashboard
	const { button_support } = data.saudeDigital.metadata.advice

	useEffect(() => {
		refOpened?.current?.addEventListener('scroll', handleScroll)
		return () => refOpened?.current?.removeEventListener('scroll', handleScroll)
	}, [totalPage, page])

	useEffect(() => {
		ref?.current?.addEventListener('scroll', handleScrollTwo)
		return () => ref?.current?.removeEventListener('scroll', handleScrollTwo)
	}, [totalPageTwo, pageTwo])

	useEffect(() => {
		const { session } = state
		if (session) {
			const { isDoctor, cpf } = state.session
			if (isDoctor) {
				fetchDoctorInfo(cpf)
			}
		}
	}, [])

	useEffect(() => {
		const timer = window.setInterval(() => {
			if (!showMessage && !showCancelAppointment.open && !showNewAppointment) {
				setConsultationClosed([])
				setNewList([])
				setPage(prev => {
					if (prev === 0) fetchAppointment(0, limit, true, false, 'opened')
					return 0
				})
				setPageTwo(prev => {
					if (prev === 0) fetchAppointment(0, limit, true, false, 'closed')
					return 0
				})
			}
		}, 40000)
		return () => {
			window.clearInterval(timer)
		}
	}, [showMessage, showCancelAppointment, showNewAppointment, state, filterText])

	useEffect(() => {
		const listAppointments = mapAppointmentResult(newList)
		const listWithOriginalDataTime = orderDate(
			[...listAppointments, ...supportList].map(item => ({
				...item,
				date: dateToISOWithTime(item.date, item.hour)
			})),
			'asc'
		)
		setAppointments(
			listWithOriginalDataTime.map(item => ({
				...item,
				date: isoStringToDateString(item.date),
				hour: isoStringToHourString(item.date)
			}))
		)
	}, [newList, supportList])

	useEffect(() => {
		setNewList([])
		setPage(prev => {
			if (prev === 0) {
				fetchAppointment(0, limit, true, false, 'opened')
			}
			return 0
		})
		setPageTwo(prev => {
			if (prev === 0) {
				fetchAppointment(0, limit, true, false, 'closed')
			}
			return 0
		})
	}, [state])

	useEffect(() => {
		fetchAppointment(page, limit, false, true, 'opened')
	}, [page])
	useEffect(() => {
		fetchAppointment(pageTwo, limit, false, true, 'closed')
	}, [pageTwo])

	useEffect(() => {
		const listAppointments = mapAppointmentResult(newList)
		const listWithOriginalDataTime = orderDate(
			[...listAppointments, ...supportList].map(item => ({
				...item,
				date: dateToISOWithTime(item.date, item.hour)
			})),
			'asc'
		)
		setAppointments(
			listWithOriginalDataTime.map(item => ({
				...item,
				date: isoStringToDateString(item.date),
				hour: isoStringToHourString(item.date)
			}))
		)
	}, [newList, supportList])

	useEffect(() => {
		if (showNewAppointment || showCancelAppointment.open) {
			setFetchOnReturn(true)
		} else if (fetchOnReturn) {
			setFetchOnReturn(false)
			fetchAppointment(page, limit, true, false, 'opened')
			fetchAppointment(page, limit, true, false, 'closed')
		}
	}, [showNewAppointment, showCancelAppointment /* , state */])

	useEffect(() => {
		verifyValidSignature()
	}, [showDigitalCertificateModal])

	useEffect(() => {
		if (pageTwo > 0) setPageTwo(prev => 0)
		else fetchAppointment(0, limit, true, false, 'closed')
	}, [filterText])

	const handleScroll = () => {
		if (
			refOpened?.current?.scrollHeight * 0.8 <=
			refOpened?.current?.offsetHeight + refOpened?.current?.scrollTop &&
			!scrollInfinit &&
			totalPage > page
		) {
			scrollInfinit = true
			setPage(prev => prev + 1)
		}
	}

	const handleScrollTwo = () => {
		if (
			ref?.current?.scrollHeight * 0.8 <= ref?.current?.offsetHeight + ref?.current?.scrollTop &&
			!scrollInfinitClosed &&
			totalPageTwo > pageTwo
		) {
			scrollInfinitClosed = true
			setPageTwo(prev => prev + 1)
		}
	}

	const verifyEligibility = useCallback(
		data => async () => {
			const { eligibilityId } = data
			if (eligibilityId) {
				setLoading(true)

				const isEligible = await validateEligibilityId(eligibilityId)

				if (isEligible) {
					handleStart(data)
				} else {
					setLoading(false)
					setShowMessage(true)
					setMessageToShow({
						title: 'Atenção',
						message:
							'A forma de pagamento escolhida para esta consulta não se encontra elegível no momento',
						buttonText: 'Voltar'
					})
					setEligibilityFailureMessage(
						'A forma de pagamento escolhida para esta consulta não se encontra elegível no momento'
					)
				}
			} else {
				handleStart(data)
			}
		},
		[]
	)

	const verifyValidSignature = () => {
		if (showDigitalCertificateModal) {
			// setIsDigitalCertificate(true)
		}
	}

	const handleDigitalCertificateModal = (value?: boolean) => setIsDigitalCertificate(!!value)

	const handleAcceptance = (param: boolean) => setDigitalCertificateAcceptance(param)

	const fetchDoctorInfo = async (cpf: string) => {
		const response: ObjectLiteral | undefined = await getDoctorInfo(cpf)
		if (response && response?.status === 200) {
			const doctorInfo = response?.data
			return dispatch
				? dispatch({
					type: TYPES.SET_DOCTOR_CURRENT,
					payload: doctorInfo
					// tslint:disable-next-line: indent
				})
				: null
		}
	}

	const appointmentsByDay: IAppointment[][] = useMemo(
		() =>
			Object.values(
				appointments.reduce((obj: { [key: string]: IAppointment[] }, item: IAppointment) => {
					obj[item.date] = (obj[item.date] || []).concat([item])
					return obj
				}, {})
			),
		[appointments]
	)

	const handleStart = ({
		consultationId,
		link,
		cpf,
		name,
		email,
		phone,
		birthDay,
		doctorEmail,
		medicalEncounterId
	}) => {
		dispatch &&
			dispatch({
				type: TYPES.SET_PATIENT,
				payload: {
					consultationId,
					cpf,
					name,
					email,
					phone,
					birthDay,
					doctorEmail,
					link,
					newConsultation: true && !medicalEncounterId
				}
			})
		navigate(PATIENT_DETAIL)
	}

	// const handleStart = useCallback(
	// 	({
	// 		consultationId,
	// 		link,
	// 		cpf,
	// 		name,
	// 		email,
	// 		phone,
	// 		birthDay,
	// 		doctorEmail,
	// 		medicalEncounterId,
	// 	}) => () => {
	// 		dispatch &&
	// 			dispatch({
	// 				type: TYPES.SET_PATIENT,
	// 				payload: {
	// 					consultationId,
	// 					cpf,
	// 					name,
	// 					email,
	// 					phone,
	// 					birthDay,
	// 					doctorEmail,
	// 					link,
	// 					newConsultation: true && !medicalEncounterId,
	// 				},
	// 			})
	// 		navigate(PATIENT_DETAIL)
	// 	},
	// 	[]
	// )

	const handleCancel = useCallback(
		(id: string) => () => {
			setAppointmentToCancel(id)
			setShowCancelAppointment({ open: true, type: 'appointment' })
		},
		[]
	)

	const handleCancelSupport = useCallback(
		(id: string) => () => {
			setAppointmentToCancel(id)
			setShowCancelAppointment({ open: true, type: 'support' })
		},
		[]
	)

	const resendLink = () => {
		setLoading(true)
		setShowConfirmResend(false)
		sendUrl(idResendLink, typeResend)
			.then(response => {
				if (response?.status <= 204) {
					setMessageToShow({ message: 'Link enviado com sucesso!' })
				} else {
					setMessageToShow({
						title: 'Pedimos desculpas',
						message: response?.data?.message
					})
				}
			})
			.catch(e => {
				setMessageToShow({
					title: 'Pedimos desculpas',
					message: JSON.stringify(e)
				})
			})
			.finally(() => {
				setShowMessage(true)
				setLoading(false)
			})
	}

	const handleShowClientInfo = useCallback(
		({ consultationId, cpf, name, email, phone, birthDay, doctorEmail, link }) => () => {
			dispatch &&
				dispatch({
					type: TYPES.SET_PATIENT,
					payload: {
						// @ts-ignore
						lastConsulting: consultationClosed.find(item => item.cpf === cpf),
						consultationId,
						cpf,
						name,
						email,
						phone,
						birthDay,
						doctorEmail,
						link,
						newConsultation: false
					}
				})
			navigate(PATIENT_DETAIL)
		},
		[]
	)

	const fetchAppointment = (
		page: number,
		limit: number,
		clearCurrent: boolean,
		withoutLoading: boolean,
		status: string
	) => {
		let filter = ''
		if (status === 'opened') {
			if (isFetchingOpened) return
			isFetchingOpened = true
		} else {
			if (isFetchingClosed) return
			isFetchingClosed = true
			filter = filterText
		}
		if (!withoutLoading) {
			setLoading(true)
		}
		get({ page, limit, status: statusEnum[status], filter }, state)
			.then(response => {
				let newListLocal = []
				if (response?.status === 200) {
					const fetchedList = response?.data?.items || []
					const total = response?.data?.total || 0
					if (status === 'opened') {
						if (total > limit) setTotalPage(parseInt(total / limit) + (total % limit !== 0 ? 1 : 0))

						if (clearCurrent) {
							newListLocal = fetchedList
						} else {
							newListLocal = [...newList, ...fetchedList]
						}
						setNewList(newListLocal)
					} else {
						if (total > limit)
							setTotalPageTwo(parseInt(total / limit) + (total % limit !== 0 ? 1 : 0))

						if (clearCurrent) {
							newListLocal = mapAppointmentClosedResult(fetchedList)
						} else {
							newListLocal = [...consultationClosed, ...mapAppointmentClosedResult(fetchedList)]
						}
						setConsultationClosed(newListLocal)
					}
					scrollInfinit = false
					scrollInfinitClosed = false
				} else if (response?.status === 204 || response?.status === 404) {
					if (process.env.NODE_ENV === 'development') {
						console.log('sem agendamentos')
					}
				} else if (!withoutLoading) {
					setMessageToShow({
						title: 'Pedimos desculpas',
						message: response?.data?.message
					})
					setShowMessage(true)
				}
			})
			.catch(e => {
				setMessageToShow({
					title: 'Pedimos desculpas',
					message: JSON.stringify(e),
					buttonText: 'Ok, entendi'
				})
				setShowMessage(true)
				logout()
			})
			.finally(() => {
				setLoading(false)
				if (status === 'opened') isFetchingOpened = false
				else isFetchingClosed = false
			})
		if (status === 'opened') getSupportConsultations()
	}

	const getSupportConsultations = () => {
		// TODO: endpoint ainda não diferenciando por clientId e não precisa para bradesco ainda
		// setLoading(true)
		// getSupport()
		// 	.then(response => {
		// 		if (response?.status === 200) {
		// 			const items = (response?.data || []).map(item => ({
		// 				...item,
		// 				isSupport: true,
		// 				date: isoStringToDateString(item.scheduledTo),
		// 				hour: isoStringToHourString(item.scheduledTo),
		// 				link: item.requester.link,
		// 			}))
		// 			setSupportList(items)
		// 		}
		// 	})
		// 	.catch(e => {
		// 		setMessageToShow({
		// 			title: 'Pedimos desculpas',
		// 			message: 'JSON.stringify(e)',
		// 			buttonText: 'Ok, entendi',
		// 		})
		// 		setShowMessage(true)
		// 		logout()
		// 	})
		// 	.finally(() => {
		// 		setLoading(false)
		// 	})
	}

	const handleFilter = () => {
		setFilterText(searchText)
	}

	const handleConfirmResend = useCallback(
		(id: string, type: string) => () => {
			setIdResendLink(id)
			setTypeResend(type)
			setShowConfirmResend(true)
		},
		[]
	)

	const newAppointmentParam: INewAppointment = {
		setShowNewAppointment,
		setLoading,
		setMessageToShow,
		setShowMessage
	}

	const cancelAppointmentParam: ICancelAppointment = {
		setShowCancelAppointment: () => {
			setShowCancelAppointment({ open: false })
			getSupportConsultations()
			setConsultationClosed([])
			setNewList([])
			setPage(prev => {
				if (prev === 0) fetchAppointment(0, limit, true, false, 'opened')
				return 0
			})
			setPageTwo(prev => {
				if (prev === 0) fetchAppointment(0, limit, true, false, 'closed')
				return 0
			})
		},
		appointmentToCancel,
		setLoading,
		setMessageToShow,
		setShowMessage,
		state,
		type: showCancelAppointment.type
	}

	const handleCopy = () => {
		setMessageToShow({ message: 'Link copiado para a área de transferência' })
		setShowMessage(true)
	}

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const searchable = e.target.value
		setSearchText(searchable)
	}

	// handles mobile
	const handleMobielMenu = (name: string) => () => setMobileActive(name)

	const handleSupport = () => {
		setShowSupport(!showSupport)
		getSupportConsultations()
	}

	const handleOpenNewAppointment = useCallback(() => setShowNewAppointment(true), [])

	return (
		<>
			{modalTour}
			{loading && <Loader />}
			<TemplatePage>
				{showMessage && (
					<MessageModal
						maxWidth='450px'
						onClose={setShowMessage}
						title={messageToShow?.title}
						message={messageToShow?.message}
						buttonText={messageToShow?.buttonText}
					/>
				)}
				{isDigitalCertificate && (
					<DigitalCertificateModal
						digitalCertificateAcceptance={digitalCertificateAcceptance}
						handleAcceptance={handleAcceptance}
						handleModal={handleDigitalCertificateModal}
					/>
				)}
				{showCancelAppointment.open && <CancelAppointment {...cancelAppointmentParam} />}
				{showConfirmResend && (
					<ConfirmResend
						setShowModal={setShowConfirmResend}
						onConfirm={resendLink}
						type={typeResend}
					/>
				)}
				{showNewAppointment && <NewAppointment {...newAppointmentParam} />}
				<MobileMenuHome active={mobileActive} onChange={handleMobielMenu} />
				<ComponentWrapper>
					<SidebarContainer
						ref={ref}
						className='left'
						size='3'
						tablet='4'
						mobile='12'
						mobileActive={mobileActive === 'closed'}
					>
						{/* <Col className='left' size='3' sm='12' mobileActive={mobileActive === 'closed'}> */}
						<Grid>
							<Col size='12' padding='0 22px'>
								<OldAppointmentTitle bold fontSize='24px/32px'>
									{consultationsCarriedOut}
								</OldAppointmentTitle>
							</Col>
							<Col size='12' padding='0 22px'>
								<Search
									id='input-search-closed-consultation'
									onChange={handleSearch}
									onKeyPress={handleFilter}
									onSearch={handleFilter}
									label='Pesquise'
								/>
							</Col>

							{consultationClosed.map((item: IConsultationClosed, index: number) => (
								<Col
									size='12'
									key={`col-card-close-consultatin-${index}`}
									id={`col-card-close-consultatin-${index}`}
								>
									<ConsultationClosed
										isDoctor={state.session.isDoctor}
										onShowInfo={handleShowClientInfo}
										{...item}
									/>
								</Col>
							))}
						</Grid>
					</SidebarContainer>
					<ContentWrapper
						className='right'
						size='9'
						tablet='8'
						mobile='12'
						mobileActive={mobileActive !== 'closed'}
						ref={refOpened}
					>
						{!loading && appointmentsByDay.length === 0 && (
							<NoContentGrid>
								<Col startFrom='9' size='3' mobile='12' padding='2vw 0 0'>
									<Button
										id='button-new-appointment'
										width='auto'
										p='0px 10px'
										onClick={handleOpenNewAppointment}
									>
										<IconCalendar src={iconCalendar} /> {buttonNewAppointment}
									</Button>
								</Col>
								<Col size='12' flex justifyContent='center'>
									<EmptyState width='30vw' height='auto' />
								</Col>
								<Col size='12' flex justifyContent='center' m='0 20vw'>
									<Span bold fontSize='24px/24px' color='red' wrap textAlign='center'>
										Você ainda não tem nenhuma consulta agendada!
									</Span>
								</Col>
								<Col size='12' justifyContent='center' m='0 20vw'>
									<Span fontSize='14px/20px' wrap textAlign='center' display='block'>
										Aqui você poderá ver todas as suas consultas separadas por data!
									</Span>
									<Span fontSize='14px/20px' wrap textAlign='center' display='block'>
										Para criar um agendamento, basta clicar no botão “Nova consulta”.
									</Span>
								</Col>
								<Col size='12' flex justifyContent='center'>
									<ButtonLink
										color='white'
										border='primary'
										p='0px 20px'
										onClick={handleOpenNewAppointment}
										width='auto'
									>
										{buttonNewAppointment}
									</ButtonLink>
								</Col>
							</NoContentGrid>
						)}

						{appointmentsByDay.map((item: IAppointment[], i: number) => (
							<Grid padding='38px 0 0 0' key={`group-appointments-${i}`} rowGap='10px'>
								<Col size='12' flex justifyContent='space-between'>
									<Grid fullWidth>
										<Col size='8' tablet='7' mobile='12'>
											<Grid>
												<Col size='12'>
													<Span wrap bold fontSize='32px/40px' fontsm='18px'>
														{isToday(item[0].date)
															? 'Suas consultas para hoje'
															: formatDate(item[0].date)}
													</Span>
												</Col>
												<Col size='12'>
													<Span fontSize='16px/24px'>
														(Total de {item.length} paciente{item.length > 1 ? 's' : ''})
													</Span>
												</Col>
											</Grid>
										</Col>
										{i === 0 && (
											<ColButton size='4' tablet='5' mobile='12' flex justifyContent='flex-end'>
												<Button
													id='button-new-appointment'
													p='0px 10px'
													width='auto'
													onClick={handleOpenNewAppointment}
												>
													<IconCalendar src={iconCalendar} /> {buttonNewAppointment}
												</Button>
											</ColButton>
										)}
									</Grid>
								</Col>
								{item.map((appointment: IAppointment, index: number) => {
									return (
										<Col
											size='4'
											lapTop='6'
											mobile='12'
											id={`col-card-open-consultatin-${index}-${i}`}
											key={`col-card-open-consultatin-${index}-${i}`}
										>
											{!appointment.isSupport ? (
												<Appointment
													onCopy={handleCopy}
													onStart={verifyEligibility}
													onCancel={handleCancel}
													onNewUrl={handleConfirmResend}
													{...appointment}
												/>
											) : (
												<SupportCard
													onStart={handleStart}
													onCancel={handleCancelSupport}
													{...appointment}
												/>
											)}
										</Col>
									)
								})}
							</Grid>
						))}
					</ContentWrapper>
				</ComponentWrapper>
				{showSupport && <Support handleToggleSupport={handleSupport} />}
				<SupportButton id='cuidar-support-button' onClick={handleSupport} showSupport={showSupport}>
					<SupportIcon />
					<SupportText>{button_support}</SupportText>
				</SupportButton>
			</TemplatePage>
		</>
	)
}

const query = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "bradesco" }) {
			metadata {
				dashboard {
					consultationsCarriedOut: consultas_realizadas
					paragraph: nao_possui_consultas_confirmadas
					buttonNewAppointment: botao_novo_agendamento
				}
				advice: assessoria {
					button_support: botao_suporte
				}
			}
		}
	}
`

export default Dashboard
